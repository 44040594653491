<template>
  <div>
    <div class="white d-flex align-center justify-space-between">
      <div
        class="d-flex align-baseline ma-0 pa-2 pl-7"
        style="gap: 10px; height: 56px"
      >
        <div>
          <v-autocomplete
            class="dark-placeholder"
            v-model="project_id"
            :items="allProjects"
            item-text="name"
            dense
            solo
            outlined
            @change="filterTimeSheet"
            placeholder="All Projects"
            flat
            clearable
            item-value="project_id"
            hide-details
          >
          </v-autocomplete>
        </div>

        <div>
          <v-autocomplete
            v-model="employees_id"
            :items="employeesList"
            item-text="name"
            outlined
            item-value="id"
            placeholder="Select Employee"
            class="d-inline-block custom-data-table-select-solo"
            dense
            solo
            clearable
            flat
            hide-details
            @change="filterTimeSheet"
            style="max-width: 200px; font-size: 14px"
          />
        </div>
        <div>
          <v-autocomplete
            v-model="year"
            :items="yearsList"
            outlined
            item-text="name"
            item-value="list_id"
            placeholder="Select Employee"
            class="d-inline-block custom-data-table-select-solo"
            dense
            @change="filterTimeSheet"
            clearable
            flat
            hide-details
            style="max-width: 200px; font-size: 14px"
          />
        </div>
        <div>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                label="Select Date"
                outlined
                dense
                v-bind="attrs"
                v-on="on"
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              no-title
              scrollable
              range
              @change="filterTimeSheet"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(date)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>
        <div>
          <v-btn shaped depressed color="primary" @click="filterTimeSheet">
            Search
          </v-btn>
        </div>
      </div>
      <div class="pr-7">
        <v-btn
          v-if="employees_id"
          color="secondary"
          dark
          class="border-radius-6 custom-data-table-action-btn mr-3"
          :to="`/admin/timeSheet/add/${employees_id}`"
        >
          <v-icon class="mr-1"> mdi-plus </v-icon>
          Add Timesheet
        </v-btn>
        <v-btn @click="downloadExcel" color="secondary">
          <ExportIcon /> Export</v-btn
        >
      </div>
    </div>

    <div class="mt-2">
      <v-card tile class="pa-3 pt-8" flat>
        <v-data-table
          :headers="headers"
          :items="timeSheetList"
          item-key="id"
          :expanded.sync="expanded"
          class="custom-data-table mx-4 cus_table"
          show-expand
          single-expand
          hide-default-footer
          disable-pagination
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td
              :colspan="headers.length"
              style="background-color: #f0f6fc"
              class="pa-2 pt-0"
            >
              <div class="row white ma-0">
                <div
                  class="col text-left secondary--text"
                  style="min-width: 15%; max-width: 15%"
                >
                  <span class="sub-header">Project Name </span>
                </div>
                <div
                  style="max-width: 15%; min-width: 15%"
                  class="col text-left secondary--text"
                >
                  <span class="sub-header">Task Status </span>
                </div>
                <div
                  class="col text-left secondary--text"
                  style="max-width: 15%; min-width: 15%"
                >
                  <span class="sub-header">Hours </span>
                </div>
                <div class="col text-left secondary--text">
                  <span class="sub-header">Task Detail </span>
                </div>
                <div class="col text-right secondary--text mr-4">
                  <router-link
                    :to="`/admin/timeSheet/edit/${item.date}/${item.employee_id}`"
                    ><PencilIcon />
                  </router-link>
                </div>
              </div>
              <div style="height: 5px"></div>

              <div class="w-100 white" v-for="i in item.entries" :key="i.id">
                <div class="d-flex align-baseline pl-4">
                  <div class="text-left" style="max-width: 15%; min-width: 15%">
                    <span class="sub-data">
                      {{ i.project.name }}
                    </span>
                  </div>
                  <div class="text-left" style="max-width: 15%; min-width: 15%">
                    <span class="sub-data">
                      {{ i.status === 0 ? "Pending" : "Completed" }}
                    </span>
                  </div>
                  <div class="text-left" style="max-width: 15%; min-width: 15%">
                    <span class="sub-data">
                      {{ convertToTimeString(i.time) }}
                    </span>
                  </div>
                  <div class="pa-3 pl-1">
                    <p class="text-justify" v-html="taskList(i.task)"></p>
                  </div>
                </div>
                <div
                  class="ma-0 pa-0"
                  style="background-color: #f0f6fc; height: 2px"
                ></div>
              </div>
            </td>
          </template>
          <template v-slot:item.date="{ item }">
            {{ formateDate(item.date) }}
          </template>
          <template v-slot:item.totalTime="{ item }">
            {{ convertToTimeString(item.totalTime) }}
          </template>
          <template v-slot:item.employee_name="{ item }">
            <div>
              <Avatar
                :color="item.entries[0].employees.color"
                :size="'30px'"
                :intials="item.entries[0].employees.intials"
                :profile_url="item.entries[0].employees.profile_url"
                class="mr-2 my-2"
              />

              {{ item.entries[0].employees.name }}
            </div>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <v-snackbar
      v-model="snackbarError"
      :timeout="3000"
      color="deep-orange accent-4"
      align-baseline="center"
    >
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbarError = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import moment from "moment";
import Avatar from "../common/basic/Avatar.vue";
import * as XLSX from "xlsx";
import ExportIcon from "../../assets/icons/ShareFat.vue";
import { mapMutations, mapState } from "vuex/dist/vuex.common.js";
import PencilIcon from "../../assets/icons/PencilIcon.vue";

export default {
  data() {
    return {
      menu: false,
      date: [],
      timeSheetList: [],
      employees_id: null,
      project_id: null,
      loading: false,
      currentYear: new Date().getFullYear(),
      year: new Date().getFullYear(),
      expandedItems: [],
      message: "",
      expanded: [false],
      snackbarError: false,
      headers: [
        {
          text: "Date",
          align: "start",
          value: "date",
          width: "15%",
        },
        {
          text: "Employee Name",
          value: "employee_name",
          width: "15%",
        },
        {
          text: "Hours",
          value: "totalTime",
        },
        { text: "Actions", value: "data-table-expand", sortable: false },
      ],
    };
  },
  components: { Avatar, ExportIcon, PencilIcon },
  computed: {
    ...mapState("projects", ["allProjects"]),
    ...mapState("employees", ["employeesList", "cachesEmpData"]),
    yearsList() {
      const startYear = 2022;
      const years = [];
      for (let year = startYear; year <= this.currentYear; year++) {
        years.push(year);
      }
      return years;
    },
  },
  methods: {
    ...mapMutations("projects", ["setProjectData"]),
    ...mapMutations("employees", ["setEmployees", "isCachesEmpData"]),

    async downloadExcel() {
      this.loading = true;
      let timeSheet = [];
      // ** Parameters to be sent with the GET request
      const params = {
        startDate: moment(this.date[0], "YYYY-MM-DD").format("YYYY-MM-DD"),
        endDate: moment(this.date[1], "YYYY-MM-DD").format("YYYY-MM-DD"),
        year: this.year,
        employees_id: this.employees_id,
        project_id: this.project_id,
        excelFile: true,
      };
      await this.$axios.get("time-sheet", { params }).then((res) => {
        timeSheet = res.data.timeSheet.map((item) => ({
          Date: moment(item.date).format("YYYY-MM-DD"),
          "Employee Name": item.employees.name,
          "Project Name": item.project.name,
          "Task Detail": item.task,
          Hours: this.convertToTimeString(item.time),
        }));
        this.loading = false;
      });
      if (!timeSheet.length) {
        this.snackbarError = true;
        this.message = "No data avilable";
        return;
      }
      // Create a worksheet
      const worksheet = XLSX.utils.json_to_sheet(timeSheet);

      // Style the header row
      const range = XLSX.utils.decode_range(worksheet["!ref"]);
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cell_address = XLSX.utils.encode_cell({ c: C, r: 0 });
        if (!worksheet[cell_address]) continue;
        worksheet[cell_address].s = {
          font: {
            bold: true,
          },
        };
      }

      // Calculate the maximum width of each column
      const colWidths = timeSheet.reduce((widths, row) => {
        Object.keys(row).forEach((key, index) => {
          const value = row[key] ? row[key].toString().length + 5 : 10;
          if (!widths[index]) {
            widths[index] = { wch: value };
          } else if (value > widths[index].wch) {
            widths[index].wch = value;
          }
        });
        return widths;
      }, []);

      worksheet["!cols"] = colWidths;

      // Create a workbook
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      // Generate Excel file
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Create a Blob from the Excel file
      const blob = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });

      // Create a link element
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "data.xlsx";

      // Append the link to the body
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Clean up and remove the link
      document.body.removeChild(link);
    },
    taskList(text) {
      return text.replace(/\n/g, "<br/>");
    },
    filterTimeSheet() {
      this.get_timeSheet_list();
    },
    toggleExpand(item) {
      const index = this.expandedItems.indexOf(item);
      if (index === -1) {
        this.expandedItems.push(item);
      } else {
        this.expandedItems.splice(index, 1);
      }
    },
    isExpanded(item) {
      return this.expandedItems.includes(item);
    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    // API service for get time sheet
    get_timeSheet_list() {
      this.loading = true;

      // ** Parameters to be sent with the GET request
      const params = {
        startDate: moment(this.date[0], "YYYY-MM-DD").format("YYYY-MM-DD"),
        endDate: moment(this.date[1], "YYYY-MM-DD").format("YYYY-MM-DD"),
        year: this.year,
        employees_id: this.employees_id,
        project_id: this.project_id,
      };
      this.$axios.get("time-sheet", { params }).then((res) => {
        this.timeSheetList = res.data.timeSheet;
        this.loading = false;
      });
    },
    // API services for get project list
    get_project_list() {
      this.loading = true;

      this.$axios.get("project").then((res) => {
        this.setProjectData(res.data.projects);
        this.loading = false;
      });
    },

    // APi service for get  employee list
    getEmployeeList() {
      if (this.cachesEmpData) {
        return;
      }
      let _self = this;
      this.loading = true;
      this.$axios.get("employee").then((res) => {
        this.loading = false;

        if (res.data.success) {
          _self.setEmployees(res.data.employees);
          _self.isCachesEmpData(true);
        } else {
          this.snackbarError = true;
          _self.message = res.data.message;
        }
      });
    },
    convertToTimeString(totalMinutes) {
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`;
    },
    formateDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
  },
  mounted() {
    this.get_timeSheet_list();
    this.get_project_list();
    this.getEmployeeList();
  },
};
</script>
<style>
.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded {
  background-color: #f0f6fc !important;
}
.v-icon
  .notranslate
  .v-data-table__expand-icon
  .v-icon--link
  .mdi
  .mdi-chevron-down
  .theme--light
  .v-data-table__expand-icon--active {
  border: 1px solid red;
}
.sub-header {
  font-size: 14px;
  font-weight: 500;
}
.sub-data {
  font-size: 14px;
  font-weight: 400;
}

.dark-placeholder ::placeholder {
  color: #323338 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}

.v-data-table__expand-icon {
  color: #888888 !important;
  border: 1px solid #888888 !important;
  border-radius: 50% !important;
  height: 16px !important;
  width: 16px !important;
  padding: 9px !important;
  text-align: center !important;
}
.v-data-table__expand-icon--active {
  color: #269fd7 !important;
  border: 1px solid #269fd7 !important;
  border-radius: 50% !important;
  height: 16px !important;
  width: 16px !important;
  padding: 9px !important;
}
</style>
