<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1037_321)">
      <path
        d="M2.41638 15.525C3.70231 14.1555 7.07809 11.25 11.875 11.25V15L18.125 8.75L11.875 2.5V6.25C7.74997 6.25 2.46169 10.1914 1.87497 15.2766C1.86685 15.3424 1.8799 15.4091 1.91222 15.4671C1.94455 15.525 1.99448 15.5712 2.05479 15.5988C2.11509 15.6265 2.18264 15.6343 2.24765 15.621C2.31266 15.6077 2.37175 15.5741 2.41638 15.525Z"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1037_321">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
import IconMixin from "../../mixins/IconMixin";

export default {
  mixins: [IconMixin],
};
</script>
