<template>
  <div class="px-0" style="margin-top: 2px">
    <div class="px-0" v-if="user.role == 'employee'">
      <div tile style="height: 56px" flat class="pa-2 pl-8 pr-8 white">
        <div class="d-flex align-center" style="gap: 10px">
          <div>
            <v-autocomplete
              v-model="month"
              :items="monthList"
              item-text="name"
              dense
              solo
              outlined
              placeholder="month"
              flat
              clearable
              item-value="number"
              hide-details
            >
            </v-autocomplete>
          </div>

          <div>
            <v-select
              v-model="year"
              :items="yearsList"
              outlined
              item-text="name"
              item-value="list_id"
              placeholder="Select Employee"
              class="d-inline-block custom-data-table-select-solo"
              dense
              clearable
              flat
              hide-details
              style="max-width: 200px; font-size: 14px"
            />
          </div>

          <div>
            <v-btn shaped depressed color="primary" @click="filterTimeSheet">
              Search
            </v-btn>
          </div>
          <v-spacer />
          <v-btn
            color="secondary"
            dark
            class="px-3 border-radius-6 custom-data-table-action-btn"
            to="/timeSheet/add"
          >
            <v-icon class="mr-1"> mdi-plus </v-icon>
            Add Timesheet
          </v-btn>
        </div>
      </div>
    </div>
    <div class="mt-2" v-if="user.role == 'employee'">
      <v-card tile class="pa-3 pt-8" flat>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="timeSheetList"
          :page.sync="currentPage"
          :search="search"
          :items-per-page="15"
          class="custom-data-table mx-4"
        >
          <template v-slot:[`item.date`]="{ item }">
            {{ formateDate(item.date) || 0 }}
          </template>
          <template v-slot:[`item.totalTime`]="{ item }">
            {{ convertToTimeString(item.totalTime) }}
          </template>

          <template v-slot:[`item.Submitted`]="{ item }">
            {{ formateDate(item.entries[0].createdAt) || 0 }}
          </template>

          <!-- action links -->
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  class="cursor-pointer mr-3"
                  v-bind="attrs"
                  v-on="on"
                  @click="timeSheetDetails(item)"
                >
                  <EyeIcon :size="20" />
                </span>
              </template>
              <span>View Detail</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </div>

    <TimeSheetListForAdmin v-if="user.role == 'admin'" />
  </div>
</template>

<script>
import EyeIcon from "@/assets/icons/EyeIcon.vue";
import moment from "moment";
import TimeSheetListForAdmin from "./TimeSheetListForAdmin.vue";

export default {
  data() {
    return {
      menu: false,
      date: null,
      employees_id: null,
      project_id: null,
      currentYear: new Date().getFullYear(),
      year: new Date().getFullYear(),
      search: "",
      user: {},
      timeSheetList: [],
      loading: false,
      snackbar: false,
      snackbarError: false,
      currentPage: null,
      month: moment().month() + 1,
      monthList: [
        { name: "January", number: 1 },
        { name: "February", number: 2 },
        { name: "March", number: 3 },
        { name: "April", number: 4 },
        { name: "May", number: 5 },
        { name: "June", number: 6 },
        { name: "July", number: 7 },
        { name: "August", number: 8 },
        { name: "September", number: 9 },
        { name: "October", number: 10 },
        { name: "November", number: 11 },
        { name: "December", number: 12 },
      ],
      headers: [
        {
          text: "Date",
          align: "start",
          value: "date",
        },
        {
          text: "Hours",
          value: "totalTime",
        },
        {
          text: "Submitted at",
          value: "Submitted",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  components: {
    EyeIcon,
    TimeSheetListForAdmin,
  },
  computed: {
    yearsList() {
      const startYear = 2022;
      const years = [];
      for (let year = startYear; year <= this.currentYear; year++) {
        years.push(year);
      }
      return years;
    },
  },

  mounted() {
    this.get_timeSheet_list();
    let localUser = localStorage.getItem("user");
    this.user = JSON.parse(localUser);
  },
  methods: {
    formateDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    filterTimeSheet() {
      this.get_timeSheet_list();
    },
    timeSheetDetails(data) {
      this.$router.push({
        path: "timeSheet/edit/" + moment(data.date).format("DD-MM-YYYY"),
      });
    },
    convertToTimeString(totalMinutes) {
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`;
    },
    get_timeSheet_list() {
      // ** Parameters to be sent with the GET request
      const params = {
        year: this.year,
        month: this.month,
      };

      this.loading = true;
      this.$axios.get("time-sheet", { params }).then((res) => {
        this.timeSheetList = res.data.timeSheet;
        this.loading = false;
      });
    },
  },
};
</script>
